<template>
  <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <v-icon size="32px">notifications_active</v-icon>
          Device Alerts
        </v-toolbar-title>
        <v-spacer/>
      </v-toolbar>
      <v-divider/>

      <v-card-text>
      <v-alert dense outlined type="error" v-if="error">
        {{ errorText }}
      </v-alert>
      
      <v-card flat class="mb-5">
        <v-card-title>
          Current Alerts
        </v-card-title>
        <v-card-text>
          <v-alert dense outlined type="error" v-if="error">
            {{ errorText }}
          </v-alert>
          
          <pre>{{ deviceAlerts }}</pre>
        </v-card-text>
      </v-card>
      
      <device-events heading="Recent Organization Alerts" :showDevice="true" targetObject="orgs" :targetGUID="organization.org_guid" :startTime="eventStartTime" eventType="alert"/>
    </v-card-text>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'
  import DeviceEvents from '../device/components/DeviceEvents.vue'
  
  import moment from 'moment'
  
  export default {
    name: 'DeviceAlertListing',
    components: {
      DeviceEvents
    },
    data() {
      return {
        error: false,
        errorText: '',
        
        headers: [
          { text: 'Alert', align: 'start', value: 'label', sortable: true, width: '25%' },
          { text: 'State', align: 'start', value: 'resolved', sortable: false},
          { text: 'Device', align: 'start text-no-wrap', value: 'device_guid', sortable: false },
          { text: 'Created', align: 'start', value: 'timestamp', sortable: false },
        ],
        
        itemsPerPage: 50,
        
        eventStartTime: moment().subtract('5', 'days').toISOString(),
        
        deviceAlerts: []
      }
    },
    computed: {
      ...mapGetters('user', ['organization']),
    },
    methods: {
      loadAlerts() {
        var queryParams = {
          'pagination_size': this.itemsPerPage,
          'org_guid': this.organization.org_guid
        }
        
        this.axios.get('/device_alerts', {'params': queryParams}).then((response) => {
          console.log('DeviceAlertListing loadAlerts response', response)
          
          this.deviceAlerts = response.data.alerts
          
          this.loading = false
        }).catch((error) => {
          console.log('DeviceAlertListing loadAlerts error', error)
          this.error = true
          this.errorText = this.$helpers.parseError(error)
          this.loading = false
        })
      }
    }
  }
</script>