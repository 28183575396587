<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  > 
    <div v-if="config.name !== undefined || !id" class="mb-5">
      <v-row><h5 class="text--disabled">Input</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <v-text-field
            v-model="config.name"
            label="Input Name"
            @input="configChanged"
            :disabled="!canEdit"
            outlined
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
    
    <v-row><h5 class="text--disabled">Input Settings</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row>
      <v-col cols="7">
        <v-select
          v-model="config.video_input_type"
          :items="generateSelectItems(video_inputValues)"
          label="Video Input"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="5">
        <v-select
          v-model="config.video_rotation"
          :items="generateSelectItems(video_rotationValues, 'daemon.encoders.video.source_rotations', {0: 'ROTATE_0', 90: 'ROTATE_90', 180: 'ROTATE_180', 270: 'ROTATE_270'})"
          label="Video Input Rotation"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
    </v-row>
    
    <v-row v-if="hasFeature('daemon.input.video.resolutions')" class="mb-3 mt-1">
      <v-col cols="7">
        <h5>Supported Input Resolutions</h5>
        <v-chip
          v-for="(item, index) in featureValue('daemon.input.video.resolutions')"
          :key="index"
          outlined
          small
          class="mr-1 mt-1"
        >
          {{ item }}
        </v-chip>
      </v-col>
      <v-col cols="2">
        <h5>Interlaced</h5>
        <span>{{ featureValue('daemon.input.video.interlaced') ? 'Supported' : 'Unsupported' }}</span>
      </v-col>
      <v-col cols="3">
        <h5>Fractional Framerates</h5>
        <span>{{ featureValue('daemon.input.video.fractional_framerate') ? 'Supported' : 'Unsupported' }}</span>
      </v-col>
    </v-row>
    
    <v-row :class="(config.enable_persistent_input !== undefined) ? '' : 'mb-3'">
      <v-col cols="7">
        <v-select
          v-model="config.audio_input_type"
          :items="generateSelectItems(audio_inputValues)"
          label="Audio Input"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="5" v-if="hasFeature('daemon.input.audio.max_channels')">
        <h5>Supported Audio Channels</h5>
        <span>{{ featureValue('daemon.input.audio.max_channels') }}</span>
      </v-col>
    </v-row>
    
    <v-row v-if="config.enable_persistent_input !== undefined" class="mb-3">
      <v-col cols="4">
        <v-checkbox
          v-model="config.enable_persistent_input"
          label="Persistent Input"
          @change="configChanged"
          :disabled="!canEdit || !hasFeature('daemon.input.video.failover')"
          hide-details="auto"
          class="mt-0"
        ></v-checkbox>
      </v-col>
      <v-col cols="3">
        <v-checkbox
          v-model="config.force_av_mute"
          label="A/V Mute"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details="auto"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    
    <v-row><h5 class="text--disabled">Graphic Overlay</h5></v-row>
    <v-row class=""><v-divider /></v-row>
    
    <div v-if="config.cef !== undefined">
      <v-row class="mt-3">
        <v-col cols="12">
          <v-checkbox
            v-model="config.cef.enable"
            label="Enable HTML5 Graphic Overlay"
            @change="configChanged"
            :disabled="!canEdit"
            hide-details="auto"
            class="mt-2 mb-3"
          ></v-checkbox>
        </v-col>
      </v-row>
      <div v-if="config.cef.enable">
        <v-row class="mb-3">
          <v-col cols="12">
            <v-text-field
              v-model="config.cef.url"
              label="Overlay Content URL"
              @input="configChanged"
              :disabled="!canEdit"
              :rules="$validations.destinationAddressValidation"
              outlined
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    
    <v-row>
      <v-col cols="12">
        <v-checkbox
          v-model="config.graphic_overlay.enable"
          label="Enable Static Graphic Overlay"
          @change="configChanged"
          :disabled="!canEdit || !hasFeature('daemon.encoders.video.overlay.enabled')"
          hide-details="auto"
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <div v-if="config.graphic_overlay.enable">
      <v-row>
        <v-col cols="12">
          <v-alert
            type="info"
            text
            icon="mdi-lock-outline"
            class="mt-3"
          >
            Graphic Overlay images must be managed directly on the device at this time.
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <v-select
            v-model="config.graphic_overlay.preset_location"
            :items="generateSelectItems(graphic_overlay_preset_locationValues)"
            label="Overlay Position"
            @change="configChanged"
            :disabled="!canEdit || !hasFeature('daemon.encoders.video.overlay.enabled')"
            hide-details="auto"
            outlined
          ></v-select>
        </v-col>
      </v-row>
      
      <div v-if="config.graphic_overlay.preset_location == 'MANUAL'">
        <v-row class="mb-3">
          <v-col cols="6">
            <v-text-field
              v-model.number="config.graphic_overlay.x_position"
              label="Image X Position %"
              @input="configChanged"
              :disabled="!canEdit || !hasFeature('daemon.encoders.video.overlay.enabled')"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model.number="config.graphic_overlay.y_position"
              label="Image Y Position %"
              @input="configChanged"
              :disabled="!canEdit || !hasFeature('daemon.encoders.video.overlay.enabled')"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      
      <div v-if="config.graphic_overlay.preset_location != 'FULLSCREEN' && config.graphic_overlay.preset_location != 'LOWER_THIRD'">
        <v-row class="mt-3">
          <h5 class="text--disabled">Graphic Overlay Image Scaling</h5>
          <v-spacer/>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon small>question_mark</v-icon>
              </v-btn>
            </template>
            <span style="line-height: 1.5em">
              When 'Lock Aspect Ratio' is configured to width or height scaling will be done automatically to keep the original images aspect ratio.<br/>
              Image Width % and Image Height % represent the % of input stream covered by the overlay image.<br/>
              Example: With an output resolution 1920x1080p when the Image Width % is set to 100% the overlay image will be 1920 pixels wide.
            </span>
          </v-tooltip>
        </v-row>
        <v-row class="mb-3"><v-divider /></v-row>
        <v-row class="mb-3">
          <v-col cols="12">
            <v-select
              v-model="config.graphic_overlay.aspect_ratio_lock"
              :items="generateSelectItems(graphic_overlay_aspect_ratio_lockValues)"
              label="Lock Aspect Ratio"
              @change="configChanged"
              :disabled="!canEdit || !hasFeature('daemon.encoders.video.overlay.enabled')"
              hide-details="auto"
              outlined
            ></v-select>
          </v-col>
        </v-row>
        <v-row class="mb-3">
          <v-col cols="6" v-if="config.graphic_overlay.aspect_ratio_lock == 'NO_LOCK' || config.graphic_overlay.aspect_ratio_lock == 'LOCK_TO_WIDTH'">
            <v-text-field
              v-model.number="config.graphic_overlay.width"
              label="Image Width %"
              @input="configChanged"
              :disabled="!canEdit || !hasFeature('daemon.encoders.video.overlay.enabled')"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
          <v-col cols="6" v-if="config.graphic_overlay.aspect_ratio_lock == 'NO_LOCK' || config.graphic_overlay.aspect_ratio_lock == 'LOCK_TO_HEIGHT'">
            <v-text-field
              v-model.number="config.graphic_overlay.height"
              label="Image Height %"
              @input="configChanged"
              :disabled="!canEdit || !hasFeature('daemon.encoders.video.overlay.enabled')"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
      <div v-if="config.graphic_overlay.preset_location == 'LOWER_THIRD'">
        <v-row><h5 class="text--disabled">Graphic Overlay Image Scaling</h5></v-row>
        <v-row class="mb-3"><v-divider /></v-row>
        <v-row class="mb-3">
          <v-col cols="3">
            <v-text-field
              v-model.number="config.graphic_overlay.height"
              label="Image Height %"
              @input="configChanged"
              :disabled="!canEdit || !hasFeature('daemon.encoders.video.overlay.enabled')"
              type="number"
              :rules="$validations.percent"
              hide-details="auto"
              outlined
            ></v-text-field>
          </v-col>
        </v-row>
      </div>
    </div>
    
    <div v-if="id && !isNew && validInput && showPreview && hasFeature('daemon.encoders.video.input_preview.enabled')" class="mt-5">
      <v-row><h5 class="text--disabled">AV Input Preview</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col v-if="thumbnailEnabled" cols="12">
          <input-preview :device_guid="device_guid" :input_id="id" />
        </v-col>
        <v-col v-else cols="12">
          <v-alert
            type="info"
            text
            class="mt-3"
          >
            To utilize input preview, please ensure a running thumbnail output for this input.
          </v-alert>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Input Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 640px; white-space: pre-wrap;">{{ input }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="id && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteInput()"
      >
        Delete Input
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import ShadowMixins from '../mixins/mixins.js'
  import InputPreview from '../../../components/InputPreview.vue'

  export default {
    name: 'InputsDocument',
    
    props: ['input', 'documents', 'device_guid', 'canEdit', 'isNew'],
    
    components: {
      InputPreview
    },
    
    mixins:[ShadowMixins],
    
    data() {
      return {
        valid: true,
        
        failoverVersion: this.$helpers.parseVersion('device', 'v10.4.0'),
        CEFVersion: this.$helpers.parseVersion('device', 'v10.5.0'),
        
        showPreview: true,
        
        id: '',
        audio_input_detected: false,
        video_input_format: '',
        
        video_rotationValues: [
          'ROTATE_0',
          'ROTATE_90',
          'ROTATE_180',
          'ROTATE_270',
        ],
        
        graphic_overlay_aspect_ratio_lockValues: [
          'NO_LOCK',
          'LOCK_TO_WIDTH',
          'LOCK_TO_HEIGHT',
        ],
        
        graphic_overlay_preset_locationValues: [
          'MANUAL',
          'FULLSCREEN',
          'LOWER_THIRD',
          'TOP_LEFT',
          'BOTTOM_LEFT',
          'TOP_CENTER',
          'CENTER',
          'BOTTOM_CENTER',
          'TOP_RIGHT',
          'BOTTOM_RIGHT'
        ],
        
        config: {
          video_input_type: 'input_auto',
          video_rotation: 'ROTATE_0',
          audio_input_type: 'input_auto',
          
          //enable_persistent_input: false, # this property will get set if its available in the shadow
          //force_av_mute: false, # this property will get set if its available in the shadow
          
          //cef: { # this property will get set if its available in the shadow
          //  'enable': false,
          //  'url': ''
          //},
          
          graphic_overlay: {
            'enable': false,
            'x_position': 0,
            'y_position': 0,
            'width': 100,
            'height': 100,
            'aspect_ratio_lock': 'NO_LOCK',
            'preset_location': 'MANUAL'
          },

        }
      }
    },
    
    watch: {
      input() {
        this.updateValues()
      },
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
      
      device() {
        return this.$devices.getDevice(this.device_guid)
      },
      
      inputIndex() {
        for (var index in this.documents) {
          if (this.documents[index].id == this.input.id) {
            return index
          }
        }
        return -1
      },
      
      video_inputValues() {
        var inputValues = [
          'input_auto'
        ]
        
        if (this.hasFeature('daemon.input.video.sdi.limit')) {
          inputValues.push('input_sdi')
        }
        
        if (this.hasFeature('daemon.input.video.hdmi.limit')) {
          inputValues.push('input_hdmi')
        }
        
        if (this.device.product_name == 'edgecaster_max') {
          inputValues.push('input_tpg')
        }
        
        return inputValues
      },
      
      audio_inputValues() {
        var inputValues =  [
          'input_auto'
        ]
        
        if (this.hasFeature('daemon.input.audio.embedded.enabled')) {
          inputValues.push('input_embedded')
        }
        
        if (this.hasFeature('daemon.input.audio.analog.enabled')) {
          inputValues.push('input_3p5mm')
        }
        
        if (this.device.product_name == 'edgecaster_max') {
          inputValues.push('input_tpg')
        }
        
        return inputValues
      },
      
      thumbnailEnabled() {
        return this.device.thumbnailEnabled(this.inputIndex)
      },
      
      validInput() {
        return (this.video_input_format == 'FORMAT_UNDEFINED') ? false : true
      }
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        // check for validation errors
        if (!this.$refs.form.validate()) {
          console.log('InputsDocument ' + this.device_guid + ' validation error', this.config)
          validationErrors = true
        }
        
        // validate CEF url if cef is enabled
        if (this.config.cef && this.config.cef.enable) {
          if (!this.$validations.isValidHostname(this.config.cef.url)) {
            console.log('InputsDocument ' + this.device_guid + ' validation error: CEF URL must be a valid hostname', this.config)
            validationErrors = true
          }
        }
        
        console.log('InputsDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.input && this.input.config) {
          Object.keys(this.input).map((key) => {
            this[key] = this.input[key]
          })
          
          // htmlDecode name properties
          if (this.config.name !== undefined) {
            this.config.name = this.$helpers.htmlDecode(this.config.name)
          }
        }
        
        // populate transient values
        if (this.isNew && this.device.product_name == 'edgecaster_max' && this.$helpers.versionCheck(this.failoverVersion, this.device.daemon_version)) {
          console.log('InputsDocument populating transient properties')
          if (!this.config.enable_persistent_input) {
            this.$set(this.config, 'enable_persistent_input', false)
          }
          if (!this.config.force_av_mute) {
            this.$set(this.config, 'force_av_mute', false)
          }
        }
        
        if (this.isNew && this.device.product_name == 'edgecaster_max' && this.$helpers.versionCheck(this.CEFVersion, this.device.daemon_version)) {
          console.log('InputsDocument populating CEF properties')
          if (!this.config.cef) {
            this.$set(this.config, 'cef', {})
          }
          
          if (!this.config.cef.enable) {
            this.$set(this.config.cef, 'enable', false)
          }
          
          if (!this.config.cef.url) {
            this.$set(this.config.cef, 'url', '')
          }
        }
        
        this.configChanged()
      },
      
      deleteInput() {
        console.log('InputsDocument ' + this.device_guid + ' deleteInput')
        this.$emit('deleteInput')
      }
    }
  }
</script>