<template>
  <v-card flat>
    <v-card-title class="pr-1">
      Device Configuration
      <v-spacer/>
      <span v-if="loading" style="width: 200px" class="mx-4">
        <v-progress-linear
          color="secondary"
          indeterminate
          buffer-value="0"
        ></v-progress-linear>
      </span>
      <span v-else-if="shadowTimestamp" class="caption secondary--text mr-4"><strong>Version:</strong> {{ shadowVersion }}, <strong>Last Updated:</strong> {{ shadowTimestamp | moment("YYYY/MM/DD hh:mm:ss a") }}</span>
      <v-btn
        x-small
        outlined
        class="mr-1"
        color="secondary"
        @click="reloadShadow"
        tooltip="Reload Configuration"
        v-if="device.canEdit()"
        :disabled="loading"
      >
        Reload
      </v-btn>
      <v-btn
        x-small
        outlined
        color="accent"
        @click="refreshShadow"
        tooltip="Request config directly from the device"
        v-if="device.canEdit()"
        :disabled="loading"
      >
        Force Sync
      </v-btn>
    </v-card-title>
    
    <v-card-text class="ma-0 pa-0">
      <v-row>
        <v-col md="3" lg="3" cols="12">
          <v-list class="mb-10">
            <v-list-item-group
              v-model="selectedShadow"
              color="primary"
              mandatory
            >
              <v-list-item
                v-for="(shadow, index) in shadowNames"
                :key="index"
                :value="shadow"
              >
                <v-list-item-icon class="mr-1">
                  <v-icon>{{ shadowIcon(shadow) }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="shadow"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
        <v-col md="9" lg="9" cols="12" class="pa-0 ma-0">
          <v-alert dense outlined type="info" v-if="isConfigMissmatch" class="ma-5">
            <strong>Some of the changes were not applied...</strong>
            
            <div v-if="shadowDiff" class="mt-3">
              <h5>Config Differences</h5>
                
                <ul 
                  v-if="shadowDiff && shadowDiff[0] && shadowDiff[0].id"
                  class="ma-1 body-2"
                >
                  <li
                    v-for="(component, id) in shadowDiff" v-bind:key="id"
                  >
                    <strong>{{ shadowComponentName(component) }}</strong>
                    <ul v-if="component.changes && component.changes.length > 0">
                      <li v-for="(change, index) in component.changes" v-bind:key="index">
                        <span class=""><strong>{{ change.key }}</strong></span>: &nbsp;
                        <span v-if="!change.array">{{ parseValue(change.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(change.to) }}&nbsp;</span>
                        <span v-else-if="change.array && change.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Differences ]&nbsp;</span>
                        <ul v-else>
                          <li v-for="(arrayChange, index) in change.array" v-bind:key="index">
                            <span class=""><strong>{{ arrayChange.key }}</strong></span>:
                            <span v-if="!arrayChange.array">{{ parseValue(arrayChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(arrayChange.to) }}&nbsp;</span>
                            <span v-else-if="arrayChange.array && arrayChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Differences ]&nbsp;</span>
                            <ul v-else>
                              <li v-for="(subArrayChange, index) in arrayChange.array" v-bind:key="index">
                                <span class=""><strong>{{ subArrayChange.key }}</strong></span>:
                                <span v-if="!subArrayChange.array">{{ parseValue(subArrayChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(subArrayChange.to) }}&nbsp;</span>
                                <span v-else-if="subArrayChange.array && subArrayChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Differences ]&nbsp;</span>
                                <span v-else class="font-weight-regular text--disabled">[ Has Differences ]&nbsp;</span>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                
                <ul 
                  v-else
                  class="ma-1 body-2"
                >
                  <li v-for="(change, index) in shadowDiff" v-bind:key="index">
                    <span class=""><strong>{{ change.key }}</strong></span>: &nbsp;
                    <span v-if="!change.array">{{ parseValue(change.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(change.to) }}&nbsp;</span>
                    <span v-else-if="change.array && change.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Differences ]&nbsp;</span>
                    <ul v-else>
                      <li v-for="(arrayChange, index) in change.array" v-bind:key="index">
                        <span class=""><strong>{{ arrayChange.key }}</strong></span>:
                        <span v-if="!arrayChange.array">{{ parseValue(arrayChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(arrayChange.to) }}&nbsp;</span>
                        <span v-else-if="arrayChange.array && arrayChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Differences ]&nbsp;</span>
                        <ul v-else>
                          <li v-for="(subArrayChange, index) in arrayChange.array" v-bind:key="index">
                            <span class=""><strong>{{ subArrayChange.key }}</strong></span>:
                            <span v-if="!subArrayChange.array">{{ parseValue(subArrayChange.from) }} <v-icon x-small>mdi-arrow-right</v-icon>{{ parseValue(subArrayChange.to) }}&nbsp;</span>
                            <span v-else-if="subArrayChange.array && subArrayChange.array.length == 0" class="font-weight-regular text--disabled">[ No Visible Differences ]&nbsp;</span>
                            <span v-else class="font-weight-regular text--disabled">[ Has Differences ]&nbsp;</span>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
            </div>
          </v-alert>
          <v-alert dense outlined type="error" v-else-if="error" class="ma-5">
            {{ errorText }}
          </v-alert>
          <v-alert dense outlined type="info" v-if="!error && updatePending" class="ma-5">
            Waiting on confirmation of configuration change from device...
          </v-alert>
          <v-alert dense outlined type="info" v-if="!error && requestPending" class="ma-5">
            The device has not yet acknowledged the pending configuration changes...
          </v-alert>
          
          <component v-if="deviceShadow" :is="componentName(selectedShadow)" :deviceShadow="deviceShadow" :device_guid="device_guid"/>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
  import { mapGetters } from 'vuex'
  
  import edgecasterInputs from '../shadow/edgecaster/EdgecasterInputs.vue'
  import edgecasterEncoders from '../shadow/edgecaster/EdgecasterEncoders.vue'
  import edgecasterOutputs from '../shadow/edgecaster/EdgecasterOutputs.vue'
  import edgecasterSystem from '../shadow/edgecaster/EdgecasterSystem.vue'
  import edgecasterAlerts from '../shadow/edgecaster/EdgecasterAlerts.vue'

  export default {
    name: 'DeviceConfig',
    
    props: ['device_guid', 'device'],
    
    components: {
      edgecasterInputs,
      edgecasterEncoders,
      edgecasterOutputs,
      edgecasterSystem,
      edgecasterAlerts
    },
    
    data() {
      return {
        shadowNames: [
          'Inputs',
          'Encoders',
          'Outputs',
          'System',
          'Alerts' // TODO comment out of not ready
        ],
        
        selectedShadow: 'Inputs'
      }
    },
    
    mounted() {
      this.deviceShadow.editing = true
    },
    
    unmounted() {
      this.deviceShadow.editing = false
    },
    
    watch: {
      deviceShadow(newShadow, oldShadow) {
        oldShadow.editing = false
        newShadow.editing = true
      }
    },
    
    computed: {
      ...mapGetters('user', ['guid', 'organization']),
      
      deviceShadow() {
        if (this.device_guid && this.selectedShadow) {
          return this.$deviceShadows.getDeviceShadow(this.device_guid, this.selectedShadow)
        }
        
        return false
      },
      
      shadowTimestamp() {
        if (this.deviceShadow && this.deviceShadow.reported.timestamp) {
          return this.deviceShadow.reported.timestamp
        }
        
        return false
      },
      
      shadowVersion() {
        return this.deviceShadow.version()
      },
      
      shadowDiff() {
        if (this.deviceShadow && this.deviceShadow.shadowDiff) {
          return this.deviceShadow.shadowDiff
        }
        
        return false
      },
      
      loading() {
        if (this.deviceShadow && (!this.deviceShadow.ready || this.deviceShadow.updatePending)) {
          return true
        }
        
        return false
      },
      
      updatePending() {
        if (this.deviceShadow && this.deviceShadow.updatePending) {
          return true
        }
        
        return false
      },
      
      requestPending() {
        if (this.deviceShadow && this.deviceShadow.requestPending) {
          return true
        }
        
        return false
      },
      
      error() {
        if (this.deviceShadow && this.deviceShadow.error) {
          return true
        }
        
        return false
      }, 
      
      errorText() {
        if (this.deviceShadow && this.deviceShadow.errorText) {
          return this.deviceShadow.errorText
        }
        
        return false
      },
      
      isConfigMissmatch() {
        if (this.deviceShadow && this.deviceShadow.errorText && this.deviceShadow.errorText == 'Config mismatch!') {
          return true
        }
        
        return false
      }
    },
    
    methods: {
      shadowIcon(shadowName) {
        if (shadowName == 'Inputs') {
          return 'input'
        } else if (shadowName == 'Encoders') {
          return 'memory'
        } else if (shadowName == 'Outputs') {
          return 'output'
        } else if (shadowName == 'System') {
          return 'settings'
        } else if (shadowName == 'Alerts') {
          return 'notifications_active'
        }
        return 'question_mark'
      },
      
      componentName(shadowName) {
        if (shadowName) {
          //return this.device.product_name + shadowName
          return 'edgecaster' + shadowName
        }
        return 'edgecasterInputs'
        //return this.device.product_name + 'Inputs'
      },
      
      shadowComponentName(item) {
        if (item.name) {
          return item.name
        } else if (this.selectedShadow == 'Inputs') {
          return 'Input #' + item.id
        } else if (this.selectedShadow == 'Encoders') {
          return 'Encoder #' + item.id
        } else if (this.selectedShadow == 'Outputs') {
          return 'Output #' + item.id
        } else if (this.selectedShadow == 'System') {
          return 'System'
        } else if (this.selectedShadow == 'Alerts') {
          return 'Alerts'
        }
        return 'Unknown'
      },
      
      parseValue(value) {
        if (value === undefined) {
          return 'undefined'
        } else if (value === '') {
          return '\'\''
        }
        return this.$helpers.getLabel(value)
      },
      
      isTimestamp(stateName) {
        if (stateName == 'timestamp') {
          return true
        }
        return false
      },
      
      reloadShadow() {
        if (this.deviceShadow) {
          this.deviceShadow.resetError()
          this.deviceShadow.fetchShadow(true)
        }
      },
      
      refreshShadow() {
        if (this.deviceShadow) {
          this.deviceShadow.resetError()
          this.deviceShadow.requestShadowUpdate()
        }
      }
    }
  }
</script>
