<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-alert
      v-if="category != 'device'"
      color="grey"
      icon="info"
      text
    >
      <span class="text-capitalize">{{ category }}</span> alerts must be managed under <span class="text-capitalize">{{ category }}</span> settings.
    </v-alert>
    <v-row class="mb-3">
      <v-col cols="12">
        <v-checkbox
          v-model="config.enable"
          label="Alert Enabled"
          @change="configChanged"
          :disabled="!canEdit || !name"
          hide-details
          class="mt-0"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row><h5 class="text--disabled">Alert Config</h5></v-row>
    <v-row class="mb-3"><v-divider /></v-row>
    <v-row class="mb-3" align="center">
      <v-col cols="4">
        <span class="subtitle-1">{{ $helpers.getLabel(name) }}</span>
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="config.threshold_condition"
          :items="threshold_conditionValues"
          label="Threshold Condition"
          @change="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="4">
        <v-text-field
          v-if="type == 'metric'"
          v-model.number="config.threshold_value"
          label="Threshold Value"
          @input="configChanged"
          :disabled="!canEdit"
          type="number"
          outlined
          hide-details
        ></v-text-field>
        <v-text-field
          v-else
          v-model="config.threshold_value"
          label="Threshold Value"
          @input="configChanged"
          :disabled="!canEdit"
          outlined
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row><h5 class="text--disabled">Notification Recipients</h5></v-row>
    <v-row><v-divider /></v-row>
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-checkbox
          v-model="config.notify_on_close"
          label="Send follow-up notification when the alert closes"
          @change="configChanged"
          :disabled="!canEdit"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <user-selection v-model="selectedUsers" :disabled="!canEdit" />
        <p class="mb-8 caption">
          Users in your organization that should receive email notifications for this alert.
        </p>
        
        <email-selection v-model="selectedEmails" :disabled="!canEdit" />
        <p class="caption">
          Additional email addresses that should receive email notifications for this alert. This can be used for external automation/bot integrations or email distribution lists.
        </p>
      </v-col>
    </v-row>
    
    <div v-if="!isNew && shadowDebug" class="mt-5">
      <v-row><h5 class="text--disabled">Alert Details</h5></v-row>
      <v-row class="mb-3"><v-divider /></v-row>
      <v-row class="mb-3">
        <v-col cols="12">
          <pre style="inline-size: 600px; white-space: pre-wrap;">{{ alert }}</pre>
        </v-col>
      </v-row>
    </div>
    
    <div v-if="alert_guid && !isNew && canEdit" class="text-right">
      <v-btn
        class="ma-1 d-none d-md-inline"
        x-small
        outlined
        @click="deleteAlert()"
      >
        Delete Alert
      </v-btn>
    </div>
  </v-form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import UserSelection from '../../../../user/OrgUserSelection.vue'
  import EmailSelection from '../../../../common/EmailListSelectionComboBox.vue'
  
  export default {
    name: 'GenericAlertDocument',
    
    components: {
      UserSelection,
      EmailSelection
    },
    
    props: ['alert', 'device_guid', 'canEdit', 'isNew'],
    
    data() {
      return {
        valid: true,
        
        alert_guid: false,
        type: '',
        name: '',
        label: '',
        category: '',
        
        threshold_conditionValues: [
          { 'value': 'GreaterThanThreshold', 'text': 'Greater Than' },
          { 'value': 'LessThanThreshold', 'text': 'Less Than' },
          { 'value': 'EqualToThreshold', 'text': 'Equal To' },
          { 'value': 'NotEqualToThreshold', 'text': 'Not Equal To' },
        ],
        
        config: {
          enabled: true,
          
          alert_level: 'device', 
          
          threshold_value: 90,
          threshold_condition: 'GreaterThanThreshold',
          
          notify_on_close: true,
          
          user_list: [],
          email_list: [],
        },
        
        selectedUsers: [],
        selectedEmails: [],
      }
    },
    
    watch: {
      alert() {
        this.updateValues()
      },
      
      selectedUsers() {
        this.config.user_list = this.selectedUsers.map(user => user.user_guid)
      },
      
      selectedEmails() {
        this.config.email_list = this.selectedEmails.map(email => email)
      },
    },
    
    computed: {
      ...mapGetters('userPreferences', ['shadowDebug']),
    },
    
    mounted() {
      this.updateValues()
    },
    
    methods: {
      configChanged() {
        var validationErrors = false
        
        if (this.isNew && (!this.type || !this.name || !this.label)) {
          console.log('GenericAlertDocument ' + this.device_guid + ' validation error: Missing alert definition', this)
          validationErrors = true
        }
        
        if (!this.config.threshold_value) {
          console.log('GenericAlertDocument ' + this.device_guid + ' validation error: Missing threshold_value', this.config)
          validationErrors = true
        }
        
        console.log('GenericAlertDocument ' + this.device_guid + ' configChanged', this.config)
        this.$emit('configUpdate', this.config, validationErrors)
      },
      
      updateValues() {
        if (this.alert) {
          Object.keys(this.alert).map((key) => {
            this[key] = this.alert[key]
          })
        }
        
        if (this.config.user_list) {
          this.selectedUsers = this.config.user_list.map((user_guid) => this.$users.getUser(user_guid))
        }
        
        if (this.config.email_list) {
          this.selectedEmails = this.config.email_list.map((email) => email)
        }
        
        this.configChanged()
      },
      
      deleteAlert() {
        console.log('GenericAlertDocument ' + this.device_guid + ' deleteAlert')
        this.$emit('deleteAlert')
      }
    }
  }
</script>